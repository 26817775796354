export default {
  grid: {
    left: '5',
    top: '5',
    right: '5',
    bottom: '10'
  },
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      type: 'none'
    },
    formatter: (params) => {
      let str = ''
      params.forEach((item, index) => {
        str += item.marker + '请求' + ': ' + params[0].data + '<br>'
      })
      return params[0].axisValue + '<br>' + str
    }
  },
  xAxis: {
    show: false,
    type: 'category',
    boundaryGap: false,
    data: []
  },
  yAxis: {
    type: 'value',
    show: false
  },
  series: [
    {
      data: [],
      type: 'line',
      areaStyle: {
        color: '#3366ff',
        opacity: 0.1
      },
      showSymbol: false,
      itemStyle: {
        normal: {
          color: '#3366ff', // 改变折线点的颜色
          lineStyle: {
            color: '#3366ff' // 改变折线颜色
          }
        }
      }
    }
  ]
}
